
import React, {useState} from "react"
import Layout from "../../components/layout"

import WebappsForm from "../../components/webapps/form"

const ENTITYID="nnseminarseries"

const searchFields = [
	{"label":"Name", "dbfield": "nnseminarseries_name", "type": "text", "filtertype": "text"},
	{"label":"Organizer", "dbfield": "onsbranch_name", "type": "text", "filtertype": "text"}
];

const formFields = [
	[
		{"label":"Name", "field": "nnseminarseries_name", "value": "", "input": "text", "mode": "readonly"},
		{"label":"Organizer", "field": "onsbranch_name", "value": "", "input": "picker", "mode": "readonly"},
	]
];

const subformFields = [
	{
		"subformid": "nnseminarseriesnotification",
		"label": "Notification List",
		"table": "nnseminarseriesnotification",
		"sort": "nnseminarseriesnotification_value",
		"mobilerowtitlefieldidx":[0],
		"minrow": 1,
		"maxrow": 5,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Email Address",
				"field": "nnseminarseriesnotification_value",
				"subtotalid": "",
				"value": "",
				"input": "email",
				"mode": "required"
			},
			{
				"label": "Active",
				"field": "nnseminarseriesnotification_active",
				"subtotalid": "",
				"value": "",
				"input": "checkbox",
				"mode": "normal"
			},
			{
				"label": "Custodian/Notes",
				"field": "nnseminarseriesnotification_notes",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "normal"
			}
		],
		"footerlist": [
		]
	}
];

const SeminarseriesNotificationPage = ({location}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");

	return <Layout location={location}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			privatePage={true} usegatedcontent={true}>
			<WebappsForm
				location={location}
				allowadd={false}
				allowdelete={false}
				pagetitle={pagetitle}
				mobilerowtitlefield={["nnseminarseries_name"]}
				searchFields={searchFields}
				editFields={formFields}
				editSubFormFields={subformFields}
				entity={ENTITYID}
				token={token} />
		</Layout>
}


export default SeminarseriesNotificationPage;
